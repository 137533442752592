/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/Wayland.png",
    alt: ""
  }), "Ubuntu al fin ", React.createElement(_components.a, {
    href: "https://insights.ubuntu.com/2017/04/05/growing-ubuntu-for-cloud-and-iot-rather-than-phone-and-convergence/"
  }, "ha abandonado"), " el desarrollo de su escritorio alternativo con Unity+Mir, para volver a Gnome, esta vez con el muchísimo más moderno gestor gráfico ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/el-linux-que-se-viene"
  }, "Wayland, quizá es má correcto llamarlo protocolo gráfico"), ". Esto reconcilia a Ubuntu con gran parte de la comunidad, se acaba con una división inútil y confirma la falta de carisma de Canonical para formar una comunidad a su alrededor, cosa que por el contrario sí ha conseguido RedHat, ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/demonios-del-sistema-y-el-sexo-de-los-angeles"
  }, "no sin falta de polémica"), "."), "\n", React.createElement(_components.p, null, "Ubunto ha abierto un camino hacia una auténtica convergencia, la de las distribuciones GNU/Linux, quizá solo unidos se le pueda hacer frente al imparable Android, el que creo es el auténtico enemigo del Software Libre. Respecto a la convergencia hacia el móvil veremos nuevas soluciones construidas desde la comunidad, no me cabe duda."), "\n", React.createElement(_components.p, null, "Pero resuelta la parte gráfica en todas las distribuciones mientras tanto se ha abierto un nuevo frente, el auge de las ", React.createElement(_components.a, {
    href: "http://liberablogo.com/index.php/2017/04/12/una-aplicacion-con-docker/"
  }, "aplicaciones en contenedores"), " ha puesto en evidencia los antiguos gestores de paquetes de las distribuciones, para solucionar este problema se han creado soluciones como Flatpack y Snap, que complementan a los anteriores y nos permiten instalar aplicaciones de terceros de forma segura en nuestros sistemas. Esto puede derivar en la creación de tiendas de aplicaciones para todo el ecosistema GNU/Linux, con este objetivo creó Canonical Snap, paralelamente Flatpack se desarrolló desde Gnome, RedHat y toda la comunidad, mi apuesta en este asunto es una vez más por la solución más abierta, Flatpack, una que no te ata a la tienda de aplicaciones que Canonical decida, pero queda mucho camino por recorrer, veremos como evoluciona este tema."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
